







































































import { Vue, Component } from 'vue-property-decorator'

@Component({
    name: 'Gallery'
})
export default class extends Vue {
    activeIndex: number = 0

    menus: any[] = [
        {
            name: 'Mindset',
            path: require('@/assets/images/mindset.png')
        },
        {
            name: 'Housework',
            path: require('@/assets/images/housework.png')
        },
        {
            name: 'Cooking',
            path: require('@/assets/images/cooking.png')
        },
        {
            name: 'Care Giver',
            path: require('@/assets/images/careGiver.png')
        },
        {
            name: 'Communication',
            path: require('@/assets/images/Communication.png')
        },
    ]
}
